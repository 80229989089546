.count {
    @media (max-width: 920px) {
        padding: 32px;
    }
}
.hero{
    @media (max-width: 920px) {
        background-size: cover;
    }
}
.service {
    @media (max-width: 920px) {
        padding: 96px 0px 96px 32px;
    }

    &-inner {
        @media (max-width: 920px) {
            display: none;
        }
    }

    &-re {
        @media (max-width: 920px) {
            display: block;
        }
    }

    &-title {
        @media (max-width: 920px) {
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 48px;
        }
    }

    &-box {
        @media (max-width: 920px) {
            border-radius: 20px;
            padding: 32px;
        }
        &:last-child{
            @media (max-width: 920px) {
                margin-left: initial;
            }
        }
        &:first-child {
            @media (max-width: 920px) {
                margin-right: inherit;
            }
        }

        &:not(:last-child) {
            @media (max-width: 920px) {
                margin-right: 16px;
            }
        }

        &-icon {
            @media (max-width: 920px) {
                display: none;
            }
        }

        &-title {
            @media (max-width: 920px) {
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 76px;
            }
        }

        &-titlev2 {
            @media (max-width: 920px) {
                font-size: 22px;
                line-height: 36px;
                margin-bottom:  73px;
            }
        }

        &-titlev3 {
            @media (max-width: 920px) {
                font-size: 22px;
                line-height: 36px;
                margin-bottom: 34px;
            }
        }
    }

}

.video {
    &-style {
        @media (max-width: 920px) {
            border-radius: 20px;
            width: 100%;
            height: 210px;
            object-fit: cover;
        }
    }
}